import * as React from 'react';
import { createContext, useContext } from "react";
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useWeb3 } from './web3Context';
import { useQuery } from 'react-query'
import axios from 'axios'
import useUtils from '../hooks/useUtils';

const uint256max = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";

const PonzContext = createContext('');

PonzProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default function PonzProvider({ children }) {

    const { enqueueSnackbar } = useSnackbar();

    const {
        currentWeb3,
        account,
        checkNetwork,
        contracts,
        refreshBalance,
        getDiscountedPonzContract,
        ponzContractAddress
    } = useWeb3()

    const {
        fromWei
    } = useUtils()

    const [surrenderSelection, setSurrenderSelection] = React.useState({});
    const [wl1IsOpen, setWL1IsOpen] = React.useState(false);
    const [hasWL1, setHasWL1] = React.useState(false);
    const [wl2IsOpen, setWL2IsOpen] = React.useState(false);
    const [hasWL2, setHasWL2] = React.useState(false);
    const [mintingAllowance, setMintingAllowance] = React.useState(null);
    const [publicMintIsOpen, setPublicMintIsOpen] = React.useState(false);
    const [proof, setProof] = React.useState(null);
    const [totalSupply, setTotalSupply] = React.useState(0);
    const [costMint, setCostMint] = React.useState(2);
    const [startWL1Timestamp, setStartWL1Timestamp] = React.useState(1670612400);
    const [startWL2Timestamp, setStartWL2Timestamp] = React.useState(1670613300);
    const [startMintTimestamp, setStartMintTimestamp] = React.useState(1670614200);
    const [gameStartTimestamp, setGameStartTimestamp] = React.useState(1670785200);
    const [countPonzAlive, setCountPonzAlive] = React.useState(0);
    const [countPonzDeath, setCountPonzDeath] = React.useState(0);
    const [countPonzSurrender, setCountPonzSurrender] = React.useState(0);
    const [prizePool, setPrizePool] = React.useState(0);
    const [currentPrizeSurrender, setCurrentPrizeSurrender] = React.useState(0);
    const [currentPrize, setCurrentPrize] = React.useState(0);
    const [gameStarted, setGameStarted] = React.useState(false);
    const [countPonzers, setCountPonzers] = React.useState(0);
    const [alivePonzers, setAlivePonzers] = React.useState([]);
    const [deathPonzers, setDeathPonzers] = React.useState([]);
    const [prizesSurrender, setPrizesSurrender] = React.useState([]);
    const [endGame, setEndGame] = React.useState(false);
    const [lastPlayer, setLastPlayer] = React.useState(false);

    const countSurrenderSelection = () => {
        return Object.keys(surrenderSelection).filter(tokenId => surrenderSelection[tokenId] == true).length
    }

    const hasSurrenderSelection = () => {
        return countSurrenderSelection() > 0
    }

    const mintWithAVAX = async (qty, value) => {
        return new Promise((resolve, reject) => {
            if (!checkNetwork()) {
                reject()
                return
            }
            try {
                contracts.ponzContract.methods
                    .mintWithAVAX(
                        getProofWhitelist1(),
                        getProofWhitelist2(),
                        Number(qty)
                    )
                    .send({ from: account, value: currentWeb3.utils.toWei(String(value)) })
                    .then(function (receipt) {
                        enqueueSnackbar('Confirmed transaction!', { variant: 'success' })
                        refreshAfterMint()
                        resolve()
                    })
                    .catch(function (error, receipt) {
                        console.log(error)
                        enqueueSnackbar('Failed transaction!', { variant: 'error' })
                        reject()
                    })
            } catch (error) {
                enqueueSnackbar('Failed transaction!', { variant: 'error' })
                reject()
            }
        });
    }

    const refreshAfterMint = () => {
        refreshBalance()
        refreshPonzers()
        loadMintingAllowance()
        loadAccountInfo()
    }

    const surrender = async (ponzIds) => {
        return new Promise((resolve, reject) => {
            if (!checkNetwork()) {
                reject()
                return
            }
            try {
                contracts.ponzContract.methods
                    .surrender(ponzIds)
                    .send({ from: account })
                    .then(function (receipt) {
                        enqueueSnackbar('Confirmed transaction!', { variant: 'success' })
                        refreshAfterSurrender()
                        resolve()
                    })
                    .catch(function (error, receipt) {
                        console.log(error)
                        enqueueSnackbar('Failed transaction!', { variant: 'error' })
                        reject()
                    })
            } catch (error) {
                enqueueSnackbar('Failed transaction!', { variant: 'error' })
                reject()
            }
        });
    }

    const refreshAfterSurrender = () => {
        refreshPonzers()
        loadAccountInfo()
    }

    const claimPrize = async () => {
        return new Promise((resolve, reject) => {
            if (!checkNetwork()) {
                reject()
                return
            }
            try {
                contracts.ponzContract.methods
                    .claimPrize()
                    .send({ from: account })
                    .then(function (receipt) {
                        enqueueSnackbar('Confirmed transaction!', { variant: 'success' })
                        refreshAfterClaim()
                        resolve()
                    })
                    .catch(function (error, receipt) {
                        console.log(error)
                        enqueueSnackbar('Failed transaction!', { variant: 'error' })
                        reject()
                    })
            } catch (error) {
                enqueueSnackbar('Failed transaction!', { variant: 'error' })
                reject()
            }
        });
    }

    const refreshAfterClaim = () => {
        loadAccountInfo()
        refreshBalance()
    }

    const loadProof = async () => {
        const proof = await fetchProof()
        setProof(proof)
    }

    const loadHasWL = async () => {
        const checkWhitelist1 = await contracts.ponzContract.methods
            .checkWhitelist1(
                getProofWhitelist1(),
                account
            ).call()
        setHasWL1(checkWhitelist1)
        const checkWhitelist2 = await contracts.ponzContract.methods
            .checkWhitelist2(
                getProofWhitelist2(),
                account
            ).call()
        setHasWL2(checkWhitelist2)
    }

    const fetchProof = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/ponz/whitelist/proof`, {
            params: {
                account: account
            }
        })
        return res.data
    }

    const getProofWhitelist1 = () => {
        return proof ? proof.whitelist1 : []
    }

    const getProofWhitelist2 = () => {
        return proof ? proof.whitelist2 : []
    }

    const fetchLastActions = async (page) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/ponz/last-actions`, {
                params: {
                    page
                }
            })
            return res.data
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const refreshTotalSupply = async () => {
        let contract = getDiscountedPonzContract()
        const amount = await contract.methods
            .totalSupply().call()
        setTotalSupply(Number(amount))
    }

    const loadMintingAllowance = async () => {
        const allowance = await contracts.ponzContract.methods
            .mintingAllowance(
                getProofWhitelist1(),
                getProofWhitelist2(),
                account
            ).call()
        setMintingAllowance(Number(allowance))
    }

    const fetchPonzers = async () => {
        if (!(contracts.ponzContract && account)) return []
        const balance = await contracts.ponzContract.methods
            .balanceOf(account).call()
        const batchedPonz = await contracts.ponzContract.methods
            .batchedPonzOfOwner(account, 0, balance).call()
        if (batchedPonz.length == 0) return []
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/ponz/`, {
            params: {
                tokenIds: batchedPonz.map(item => item.tokenId)
            }
        })
        return res.data
    }

    const { data: ponzers, refetch: refetchPonzers } = useQuery(['ponzers'], fetchPonzers, {
        enabled: true
    })

    const refreshPonzers = () => {
        refetchPonzers()
    }

    const loadCheckMints = async () => {
        let contract = getDiscountedPonzContract()

        const checkWL1IsOpen = await contract.methods
            .checkWL1IsOpen().call()
        setWL1IsOpen(checkWL1IsOpen)

        const checkWL2IsOpen = await contract.methods
            .checkWL2IsOpen().call()
        setWL2IsOpen(checkWL2IsOpen)

        const checkMintIsOpen = await contract.methods
            .checkMintIsOpen().call()
        setPublicMintIsOpen(checkMintIsOpen)
    }

    const loadGameInfo = async () => {
        let contract = getDiscountedPonzContract()

        const gameStarted = await contract.methods
            .gameStarted().call()
        setGameStarted(gameStarted)


        const info = await contract.methods
            .gameStatus(alivePonzers.length == 0 ? 1 : alivePonzers.length).call()

        setCountPonzAlive(Number(info.alivePonz))
        setCountPonzDeath(Number(info.deadPonz))
        setCountPonzSurrender(Number(info.surrenderedPonz))

        setPrizePool(Number(fromWei(info.prizePool)))
        setPrizesSurrender(info.prizesSurrender)

        setEndGame(Number(info.alivePonz) == 1 || Number(info.alivePonz) == 0)
    }

    const loadAccountInfo = async () => {
        let contract = getDiscountedPonzContract()

        const currentPrize = await contract.methods
            .prize(account).call()
        setCurrentPrize(fromWei(currentPrize))

        const balance = await contract.methods
            .balanceOf(account).call()
        setCountPonzers(Number(balance))
    }

    const getSurrenderPrize = () => {
        if (endGame && !lastPlayer) return 0
        let count = countSurrenderSelection()
        if (prizesSurrender.length == 0) return 0
        if (count == 0) return Number(fromWei(prizesSurrender[0])).toFixed(3)
        let total = prizesSurrender
            .slice(0, count)
            .map(v => Number(fromWei(v)))
            .reduce((a, b) => a + b, 0)
        return total.toFixed(3)
    }

    React.useEffect(() => {
        loadCheckMints()
        loadGameInfo()
    }, []);

    React.useEffect(() => {
        setAlivePonzers([])
        setDeathPonzers([])
    }, [account])

    React.useEffect(() => {
        loadGameInfo()
    }, [alivePonzers]);

    React.useEffect(() => {
        if (account) return
        setMintingAllowance(0)
        setProof(null)
    }, [account])

    React.useEffect(() => {
        if (!(proof && account)) return
        loadMintingAllowance()
        loadHasWL()
    }, [proof, account])

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!(proof && account)) return
            loadHasWL()
        }, 3 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [proof, account])

    React.useEffect(() => {
        if (!(contracts.ponzContract && account)) return
        refreshPonzers()
        loadProof()
        loadAccountInfo()
    }, [contracts.ponzContract, account])

    React.useEffect(() => {
        const interval = setInterval(() => {
            refreshTotalSupply()
            loadGameInfo()
            loadCheckMints()
        }, 3 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [contracts.ponzContract, countPonzers, alivePonzers]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!account) return
            loadMintingAllowance()
        }, 3 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [
        contracts.ponzContract,
        account,
        getProofWhitelist1(),
        getProofWhitelist2()
    ]);

    React.useEffect(() => {
        if (!ponzers || ponzers.length == 0) return
        setAlivePonzers(ponzers.filter(ponz => [0, 1].includes(ponz.status)))
        setDeathPonzers(ponzers.filter(ponz => [2, 3].includes(ponz.status)))
    }, [ponzers]);

    React.useEffect(() => {
        refreshBalance()
    }, [currentPrize]);


    React.useEffect(() => {
        setLastPlayer(alivePonzers && alivePonzers.length == 1 && endGame)
    }, [alivePonzers, endGame]);

    return (
        <PonzContext.Provider
            value={{
                surrenderSelection,
                setSurrenderSelection,
                alivePonzers,
                hasSurrenderSelection,
                deathPonzers,
                wl1IsOpen,
                hasWL1,
                wl2IsOpen,
                hasWL2,
                mintingAllowance,
                publicMintIsOpen,
                proof,
                totalSupply,
                mintWithAVAX,
                costMint,
                startWL1Timestamp,
                startWL2Timestamp,
                startMintTimestamp,
                fetchLastActions,
                countPonzAlive,
                countPonzDeath,
                countPonzSurrender,
                prizePool,
                currentPrizeSurrender,
                currentPrize,
                claimPrize,
                surrender,
                gameStarted,
                gameStartTimestamp,
                prizesSurrender,
                getSurrenderPrize,
                endGame,
                lastPlayer
            }}
        >
            {children}
        </PonzContext.Provider>
    );
}

export const usePonz = () => useContext(PonzContext)