import * as React from 'react';
import Web3 from "web3";

const useUtils = () => {

    const currentTimeSeconds = () => {
        return Math.ceil(Date.now() / 1000);
    }

    const fromWei = (value) => {
        return Web3.utils.fromWei(value)
    }

    return {
        currentTimeSeconds,
        fromWei
    }
}

export default useUtils;