import { Circles } from 'react-loader-spinner'
import { alpha, styled } from '@mui/material/styles';

const DivRoot = styled('div')(({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#f7e92a',
    fontSize: '2rem',
    textShadow: '0 0 5px black, 0 0 8px black',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    zIndex: theme.zIndex.drawer + 5,
}));

export default function Loading({ open }) {
    return (
        <DivRoot>
            <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </DivRoot>
    )
}