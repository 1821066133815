import { useState, useEffect } from 'react';
import { createContext, useContext } from "react";
import PropTypes from 'prop-types';
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useSnackbar } from 'notistack';
//contracts
import PonzGameS1 from "../contracts/PonzGameS1.json"
import { useNavigate, useParams } from "react-router-dom";

const NETWORKID = process.env.REACT_APP_AVALANCHE_NETWORK_ID
const providerOptions = {
};
const web3Modal = new Web3Modal({
    network: NETWORKID,
    cacheProvider: true,
    providerOptions
});

const Web3Context = createContext('');

Web3Provider.propTypes = {
    children: PropTypes.node.isRequired
};

export default function Web3Provider({ children }) {

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [currentWeb3, setCurrentWeb3] = useState(null);

    const [walletConnected, setWalletConnected] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [currentNetWorkId, setCurrentNetWorkId] = useState(null);
    const [ponzContract, setPonzContract] = useState(null);

    const [avaxAmount, setAvaxAmount] = useState(0);

    const refreshBalance = async () => {
        if (!currentWeb3) return
        let avaxAmount = await currentWeb3.eth.getBalance(account)
        setAvaxAmount(Number(currentWeb3.utils.fromWei(avaxAmount)).toFixed(4))
    }

    const checkNetwork = () => {
        let valid = NETWORKID == currentNetWorkId
        if (!valid) enqueueSnackbar('Network not valid!', { variant: 'error' })
        return valid
    }

    const isAvaxCChain = () => {
        return NETWORKID == currentNetWorkId
    }

    const connectWallet = async () => {
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);

        provider.on("accountsChanged", (accounts) => {
            if (accounts.length === 0) {
                disconnectWallet()
            }
            setAccounts(accounts)
            setAccount(accounts[0])
        });

        provider.on("chainChanged", (chainId) => {
            connectWallet()
        });

        provider.on("connect", (info) => {
            console.log("connected", info)
        });

        provider.on("disconnect", (error) => {
            console.log(error)
        });

        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId()
        setCurrentWeb3(web3)
        setAccounts(accounts)
        setAccount(accounts[0])
        setWalletConnected(true)
        setCurrentNetWorkId(networkId)
    }

    const disconnectWallet = () => {
        web3Modal.clearCachedProvider()
        setAvaxAmount(0)
        setWalletConnected(false)
        setAccounts([])
        setAccount(null)
    }

    useEffect(() => {
        if (!(web3Modal && web3Modal.cachedProvider)) return
        connectWallet()
    }, [])

    useEffect(() => {
        if (!(currentWeb3 && account)) return

        const loadBalance = async (web3) => {
            let avaxAmount = await web3.eth.getBalance(account)
            setAvaxAmount(Number(web3.utils.fromWei(avaxAmount)).toFixed(4))
        }

        setPonzContract(
            new currentWeb3.eth.Contract(
                PonzGameS1.abi,
                PonzGameS1.networks[NETWORKID].address,
            )
        )

        loadBalance(currentWeb3)

    }, [currentWeb3, account])

    const getDiscountedWeb3 = () => {
        const web3 = new Web3(new Web3.providers.HttpProvider(
            process.env.REACT_APP_AVALANCHE_NETWORK_URL
        ));
        return web3
    }

    const getDiscountedPonzContract = () => {
        const web3 = getDiscountedWeb3()
        const pfpContract = new web3.eth.Contract(
            PonzGameS1.abi,
            PonzGameS1.networks[process.env.REACT_APP_AVALANCHE_NETWORK_ID].address,
        )
        return pfpContract
    }

    return (
        <Web3Context.Provider
            value={{
                currentWeb3,
                connectWallet,
                disconnectWallet,
                isConnected: walletConnected && isAvaxCChain(),
                account,
                networkId: NETWORKID,
                checkNetwork,
                refreshBalance,
                contracts: {
                    ponzContract
                },
                balance: {
                    avax: avaxAmount
                },
                getDiscountedPonzContract,
                isAvaxCChain,
                ponzContractAddress: PonzGameS1.networks[NETWORKID].address,
                walletConnected
            }}
        >
            {children}
        </Web3Context.Provider>
    );
}

export const useWeb3 = () => useContext(Web3Context)