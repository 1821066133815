import * as React from 'react';
import axios from 'axios'
import { createContext, useContext } from "react";
import PropTypes from 'prop-types';
import { JOEPEGS_COLLECTION_ADRESSES } from './settings'

const JoepegsContext = createContext('');

JoepegsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default function JoepegsProvider({ children }) {

    const getCollectionAddress = (collection) => {
        return JOEPEGS_COLLECTION_ADRESSES[collection]
    }

    return (
        <JoepegsContext.Provider
            value={{
                getCollectionAddress
            }}
        >
            {children}
        </JoepegsContext.Provider>
    );
}

export const useJoepegs = () => useContext(JoepegsContext)