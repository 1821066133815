import ReactDOM from 'react-dom';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Loading from './components/Loading';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Web3Provider from './contexts/web3Context'
import JoepegsProvider from './contexts/joepegsContext';
import PonzProvider from './contexts/ponzContext'

import { QueryClient, QueryClientProvider } from 'react-query'
import Button from '@mui/material/Button';

const queryClient = new QueryClient()

const App = lazy(() => import('./App'))

const CloseSnackbarButton = ({ messageKey }) => {

  const { closeSnackbar } = useSnackbar()


  return (
    <Button
      onClick={() => closeSnackbar(messageKey)}
      style={{
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%'
      }}
    />
  )
}

ReactDOM.render(
  <SnackbarProvider
    action={(key) => (
      <CloseSnackbarButton messageKey={key} />
    )}
    anchorOrigin={{
      horizontal: 'top',
      vertical: 'left'
    }}
    dense
    maxSnack={1}
    preventDuplicate
  >
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
            <Web3Provider>
              <JoepegsProvider>
                <PonzProvider>
                  <Suspense fallback={<Loading />}>
                    <App />
                  </Suspense>
                </PonzProvider>
              </JoepegsProvider>
            </Web3Provider>
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
//serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
